import * as React from "react";

import { Link } from "gatsby";
import Layout from "../templates/layout";

const IndexPage = () => {

  return (
      <Layout>
        <main>
          <title>Home Page</title>
          <h1>Welcome to CXC Answers!</h1>
          <p>
            We have free CXC past papers and free CXC past paper solutions.
            Click of any links below to get started.
          </p>
          <Link className="link" to={`solutions/csec/physics/CSEC-Physics-May-June-2005-Paper-2`}>- 2005 CSEC Physics May/June Paper 2</Link>
          <Link className="link" to={`solutions/csec/physics/CSEC-Physics-May-June-2006-Paper-2`}>- 2006 CSEC Physics May/June Paper 2</Link>
        </main>
      </Layout>
  );
};

export default IndexPage;
